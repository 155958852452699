<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  created() {
    this.setTheme();
  },
  methods: {
    setTheme() {
      let hours = new Date().getHours();
      if (hours >= 7 && hours < 18) {
        this.$store.state.theme = "light";
        document.querySelector("body").setAttribute("theme", "light");
      } else {
        document.querySelector("body").setAttribute("theme", "dark");
        this.$store.state.theme = "dark";
      }
    },
  },
};
</script>
