import { getConfigList } from "@/api/config";
import { listNav } from "@/api/nav";
import { setRouter } from "@/utils/handleRoutes"

const state = {
  configs: [],
  menus: [],
};
const getters = {
  configs: (state) => state.configs,
  menus: (state) => state.menus
};
const mutations = {
  setConfigs(state, configs) {
    state.configs = configs
  },
  setMenus(state, menus) {
    state.menus = menus
  },
};
const actions = {
  async getConfigList({ commit }) {
    await getConfigList().then( (res) => {
      if (res.code == 200) {
        commit('setConfigs', res.data)
      }
    })
  },
  async getNavList({ commit }) {
    await listNav().then( (res) => {
      if (res.code == 200) {
        commit('setMenus', res.data)
        setRouter()
      }
    })
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
