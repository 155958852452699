import { indexRouters } from "@/router/index"
import router from "../router";
import { asyncRoutes } from "../router";
import store from '@/store'

/**
 * 通过api获取的菜单渲染路由
 * @param routes
 * @param permissions
 */
function filterAsyncRoutes(routes, permissions) {
  const finallyRoutes = new Array();
  permissions.forEach((router) => {
    let r = null;
    r = Object.assign({}, routes.find((item) => item.name == router.code));
    // 判断是否找到路由
    if (Object.keys(r).length == 0) {
      return true;
    }
    r.path = router.link;
    if (!r.meta) {
      r.meta = {};
    }
    if (router.name) {
      r.meta.title = router.name;
    }
    if (router.icon) {
      r.meta.icon = router.icon;
    }
    if (router.redirect) {
      r.redirect = router.redirect
    }
    if (router.children) {
      r.children = filterAsyncRoutes(routes, router.children);
    }
    finallyRoutes.push(r);
  });
  return finallyRoutes;
}


export function setRouter() {
  const finallyRoutes = filterAsyncRoutes(asyncRoutes, store.getters.menus);
  indexRouters.path = "/";
  indexRouters.redirect = "/home"
  indexRouters.children = finallyRoutes
  // 添加路由
  router.addRoute(indexRouters)
  // 添加匹配不到404页面
  router.addRoute({
    path: '/:catchAll(.*)',
    redirect: '/404',
  });
}
