import request from "@/utils/request";

export function listNav() {
  return request({
    url: "/getNavList",
    method: "get",
  });
}

export function getNavByCode(code) {
  return request({
    url: "/getNavByCode",
    method: "get",
    params: {
      code,
    },
  });
}
