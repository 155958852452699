import { createRouter, createWebHistory } from "vue-router";
import store from '@/store'

const constantRoutes = [
  {
    path: "/404",
    name: "404",
    component: () => import('@/views/404'),
    meta: { title: "404" },
  },
]

export const indexRouters = { component: () => import('@/views/index') };

export const asyncRoutes = [
  {
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    name: 'archives',
    component: () => import('@/views/archives/index')
  },
  {
    name: 'categories',
    component: () => import('@/views/category/index')
  },
  {
    name: 'tags',
    component: () => import('@/views/tags/index')
  },
  {
    name: 'dynamic',
    component: () => import('@/views/dynamic/index')
  },
  {
    name: 'about',
    component: () => import('@/views/about/index')
  },
  {
    name: 'articleCustomLink',
    component: () => import('@/views/article/index'),
    meta: {
      linkType: "customLink",
    }
  },
  {
    name: 'articleLink',
    component: () => import('@/views/article/index'),
    meta: {
      linkType: "link",
    }
  },
  {
    name: 'tagDetail',
    component: () => import('@/views/tags/components/details.vue'),
  },
  {
    name: 'categoryDetail',
    component: () => import('@/views/category/components/details.vue'),
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
});

router.beforeResolve(async (to, from, next) => {
  if (store.getters.configs.length == 0) {
    await store.dispatch("getConfigList")
  }
  if (to.path == "/404") {
    next()
  } else {
    if (store.getters.menus.length == 0) {
      await store.dispatch("getNavList")
      next({ ...to, replace: true })
    } else {
      next()
    }
  }
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;

  if (to.meta.title) {
    document.title = to.meta.title + " - " + store.getters.configs["blogName"]
  }
});

export default router;
