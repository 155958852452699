import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//引入css
import "./assets/css/global.css";
import "./assets/css/animate.min.css";
//引入font-awesome
import '@fortawesome/fontawesome-free/css/all.min.css';
//引入iconfont
import "./assets/iconfont/iconfont.css";
//引入Element
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App);

//引入插件
app.use(store);
app.use(router);
app.use(ElementPlus, {
    locale: zhCn,
});
app.mount("#app");
