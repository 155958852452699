import axios from "axios";

const request = axios.create({
  baseURL: "/api/",
  timeout: 10000,
});

request.interceptors.response.use((response) => {
  return response.data;
});

export default request;
